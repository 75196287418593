@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
// Import Fuse core library
@import "@fuse/scss/core"; // Import app.theme.scss
@import "app/app.theme"; // or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions

* {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.tech-menuItem {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: 1fr;
    column-gap: 5px;
    align-items: center;
    text-align: left;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
}

.tech-button {
    --textColor: #000000;
    --bgColor: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 6px;
    text-transform: capitalize;
    padding: 8px 16px;
    border: none;
    outline: none;
    background: var(--bgColor);
    border: 1px solid var(--bgColor);
    color: var(--textColor);
    text-decoration: none;
    min-height: 36px;
    //box-shadow: 0px 0px 3px #00000044;

    &.alt {
        background: var(--textColor);
        color: var(--bgColor);
    }

    &:disabled {
        --textColor: #00000042;
        --bgColor: #0000001f;
    }

    &:not(:disabled) {
        cursor: pointer;

        &.--primary {
            --textColor: #ffffff;
            --bgColor: #2a82b8;
        }
        &.--warning {
            --textColor: #ffffff;
            --bgColor: #f1ff26;
        }
        &.--error {
            --textColor: #ffffff;
            --bgColor: #ff0000;
        }
    }
    &:hover {
        text-decoration: none;
    }
}
